import { useEffect, useState } from "react";
import Markdown from 'react-markdown'

export default function WebSocketCall({ socket }) {
  const [message, setMessage] = useState("");

  useEffect(() => {
    let m = ''
    socket.on("data", (d) => {
      const data = JSON.parse(d.data);

      if (data.audio) {
        const binaryString = window.atob(data.audio);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);

        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        console.log({
          "message": "data recieved",
          "id": data.id,
          "order": data.order,
          "audio": data.audio,
          "bytes": bytes
        });

        if (data.order !== 0) {
          return;
        }

        const blob = new Blob([bytes], { type: 'audio/ogg' });
        const audioUrl = URL.createObjectURL(blob);
        const audio = new Audio(audioUrl);

        audio.play().catch(e => console.error('Error playing audio:', e));

        audio.onended = () => {
          URL.revokeObjectURL(audioUrl);
        };
      } else if (data.text) {
        if (data.id === 0) {
          m = '';
          setMessage('');
        }

        m += data.text
        setMessage(m);
      }
    });

    return () => {
      socket.off("data", () => {
        console.log("data event was removed");
      });
    };
    // eslint-disable-next-line
  }, [socket]);

  return (
    <div>
      <Markdown>{message}</Markdown>
    </div>
  );
}
