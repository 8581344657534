import "./App.css";
import WebSocketCall from "./components/WebSocketCall";
import { io } from "socket.io-client";
import { useEffect, useState, useRef } from "react";

function App() {
  const [socketInstance, setSocketInstance] = useState(null);
  const [loading, setLoading] = useState(true);
  const mediaRecorderRef = useRef(null); // Ref to hold the MediaRecorder instance
  const [isRecording, setIsRecording] = useState(false); // State to track recording status

  useEffect(() => {
    //const socket = io("localhost:5001/", {
    const socket = io("https://james-hollister-interviewer-782603cc3cc2.herokuapp.com/", {
      transports: ["websocket"],
      cors: {
        origin: "http://localhost:3000/",
      },
    });

    setSocketInstance(socket);

    socket.on("connect", () => {
      console.log("Connected");
    });

    setLoading(false);

    socket.on("disconnect", () => {
      console.log("Disconnected");
    });

    let audioChunks = []

    // Request microphone access
    async function getMicrophoneAccess() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.ondataavailable = (event) => {
          audioChunks = [...audioChunks, event.data];
        };
      } catch (err) {
        console.error('Error accessing the microphone', err);
      }
    }

    getMicrophoneAccess();

    const handleKeyDown = (event) => {
      if (event.code === "Space" || event.code === "KeyS" || event.code === "KeyO" || event.code === "KeyG") {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state === "inactive") {
          setIsRecording(true);
          mediaRecorderRef.current.start();
        }
      }
    };

    const handleKeyUp = (event) => {
      if (event.code === "Space" || event.code === "KeyS" || event.code === "KeyO" || event.code === "KeyG") {
        setIsRecording(false);
        if (mediaRecorderRef.current && mediaRecorderRef.current.state === "recording") {
          mediaRecorderRef.current.stop();
          mediaRecorderRef.current.onstop = async () => {
            const audioBlob = new Blob(audioChunks, { 'type': 'audio/ogg; codecs=opus' }); // or 'audio/webm'
            //const audioUrl = URL.createObjectURL(audioBlob);
            //const audio = new Audio(audioUrl);
            //audio.play().catch(err => console.error('Playback error:', err));

            // TODO: Send `audioBlob` to your API here
            // For example: uploadAudio(audioBlob);
            const arrayBuffer = await audioBlob.arrayBuffer();
            socket.emit("audio", JSON.stringify({
              "key": event.code,
              "audio": bufferToBase64(arrayBuffer),
              "context": message
            }));
          };
        }
      }
    };

    function bufferToBase64(buffer) {
      let binary = '';
      const bytes = new Uint8Array(buffer);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    }

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return function cleanup() {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
      // Cleanup mediaRecorder stream to avoid leaks
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
      }
      socket.disconnect();
    };
    // eslint-disable-next-line
  }, []);

  const [message, setMessage] = useState("");

  useEffect(() => {
    if (!socketInstance) return;

    let m = ''
    socketInstance.on("data", (d) => {
      const data = JSON.parse(d.data);

      if (data.audio) {
      } else if (data.text) {
        if (data.id === 0) {
          m = '';
          setMessage('');
        }

        m += data.text
        setMessage(m);
      }
    });

    return () => {
    };
    // eslint-disable-next-line
  }, [socketInstance]);

  return (
    <div className="App">
      <>
        <div className="line">
          {!loading && <WebSocketCall socket={socketInstance} />}
          {isRecording && <div style={{ width: '100px', height: '100px', backgroundColor: 'blue' }}>Recording...</div>}
        </div>
      </>
    </div>
  );
}

export default App;
